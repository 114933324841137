import { hexToRGB } from '@apostrof/lib'

type SingleColorIndex = 'shadow'

type Colors = { [key in SingleColorIndex]: string } & {
  grays: string[]
}

const grays = [
  '#ffffff',
  '#f8fafc',
  '#f1f5f8',
  '#dae1e7',
  '#b8c2cc',
  '#8795a1',
  '#606f7b',
  '#3d4852',
  '#22292f',
  '#181a1b',
]

const colors: Colors = {
  grays,
  shadow: grays[8],
}

export const theme = {
  colors,
  fluidSpace: [
    'calc(0.5rem + 0.5%)',
    'calc(0.5rem + 1%)',
    'calc(0.5rem + 2%)',
    'calc(4rem + 2%)',
  ],
  maxWidth: '80rem',
  textShadow: `0 2px 4px ${hexToRGB(colors.shadow, 0.32)}`,
  radii: {
    normal: '0.5rem',
    pill: '999px',
  },
  elevations: [
    `0 1px 2px ${hexToRGB(colors.shadow, 0.24)},` +
      `0 1px 3px ${hexToRGB(colors.shadow, 0.12)}`,
    `0 2px 4px ${hexToRGB(colors.shadow, 0.12)},` +
      `0 3px 6px ${hexToRGB(colors.shadow, 0.15)}`,
    `0 3px 6px ${hexToRGB(colors.shadow, 0.1)},` +
      `0 5px 10px ${hexToRGB(colors.shadow, 0.15)}`,
    `0 5px 10px ${hexToRGB(colors.shadow, 0.05)},` +
      `0 10px 20px ${hexToRGB(colors.shadow, 0.15)}`,
    `0 20px 40px ${hexToRGB(colors.shadow, 0.2)}`,
  ],
}
