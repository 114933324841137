import '@/assets/fonts/loader.css'
import '@/assets/my-sanitize.css'
import { fontSmoothing, systemFontStack } from '@/utils/mixins'
import { css } from '@emotion/core'

export default css`
  html {
    font-family: ${`"PMN Caecilia Sans"`}, ${systemFontStack.join(', ')};

    ${fontSmoothing};
    /* Globalize font-smoothing because the whole site is light text on dark bg */
  }
`
