export enum PrismicLocaleCode {
  English = 'en-us',
  Indonesian = 'id',
  Korean = 'ko-kr',
  Japanese = 'ja-jp',
}

export enum DefaultLocaleKind {
  FALSE,
  TRUE,
}

interface SharedConfig {
  htmlLang: string
  switcherLabel: {
    compact: string
    extended: string
  }
}

interface DefaultLocaleConfig extends SharedConfig {
  kind: DefaultLocaleKind.TRUE
}

interface OtherLocaleConfig extends SharedConfig {
  kind: DefaultLocaleKind.FALSE
  pathPrefix: string
  browserLanguageCode: string
}

type LocaleConfig = {
  [key in PrismicLocaleCode]: DefaultLocaleConfig | OtherLocaleConfig
}

export const localeConfig: LocaleConfig = {
  [PrismicLocaleCode.English]: {
    kind: DefaultLocaleKind.TRUE,
    htmlLang: 'en-US',
    switcherLabel: {
      compact: 'EN',
      extended: 'English',
    },
  },
  [PrismicLocaleCode.Indonesian]: {
    kind: DefaultLocaleKind.FALSE,
    pathPrefix: 'id',
    browserLanguageCode: 'id',
    htmlLang: 'id-ID',
    switcherLabel: {
      compact: 'ID',
      extended: 'Bahasa Indonesia (Indonesian)',
    },
  },
  [PrismicLocaleCode.Japanese]: {
    kind: DefaultLocaleKind.FALSE,
    pathPrefix: 'ja',
    browserLanguageCode: 'ja',
    htmlLang: 'ja-JP',
    switcherLabel: {
      compact: 'JA',
      extended: '日本語 (Japanese)',
    },
  },
  [PrismicLocaleCode.Korean]: {
    kind: DefaultLocaleKind.FALSE,
    pathPrefix: 'ko',
    browserLanguageCode: 'ko',
    htmlLang: 'ko-KR',
    switcherLabel: {
      compact: 'KO',
      extended: '한국어 (Korean)',
    },
  },
}
