import globalStyles from '@/config/global-styles'
import { theme } from '@/config/theme'
import { PageContext as HomepagePageContext } from '@/pages/index'
import { LocaleContext } from '@/store/locale'
import { Global, ThemeContext } from '@emotion/core'
import React from 'react'
import { Head } from './Head'

export type PageContext = {
  htmlLang: string
}

type Props = {
  pageContext: PageContext & HomepagePageContext
}

const Layout: React.FC<Props> = ({
  children,
  pageContext: { locale, htmlLang },
}) => {
  return (
    <>
      <Global styles={globalStyles} />

      <Head htmlLang={htmlLang} />

      <LocaleContext.Provider value={locale}>
        <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
      </LocaleContext.Provider>
    </>
  )
}

export default Layout
