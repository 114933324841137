import { createContext, useContext } from 'react'
import { PrismicLocaleCode } from '../config/i18n'

export const LocaleContext = createContext<PrismicLocaleCode>(
  PrismicLocaleCode.English,
)

export function useLocale() {
  return useContext(LocaleContext)
}
